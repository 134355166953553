<template>
  <button
    class="AppDropdownOption"
    :class="{
      AppDropdownOption_disabled: option.disabled,
      AppDropdownOption_active: option.id === selectedId,
      AppDropdownOption_hr: option.hr,
    }"
    ref="button"
    tabindex="1"
    @click.prevent.stop="$emit('select', option.id)"
    @keydown.enter.prevent
    @keyup.enter.prevent.stop="$emit('select', option.id)"
    @focus="$emit('focus', index)"
  >
    <div
      v-if="isProject"
      class="AppDropdownOption__dot"
      :style="{ background: option.color }"
    ></div>

    <div
      v-else-if="withImg && option.img || option.logo"
      class="AppDropdownOption__img">
      <img :src="option.img || option.logo" />
    </div>

    <div
      v-else-if="option.icon"
      class="AppDropdownOption__icon"
    >
      <v-icon :name="option.icon" :size="16"></v-icon>
    </div>

    <div
      class="AppDropdownOption__color"
      v-else-if="withColorLabel && option.color"
      :style="{ background: option.color }"
    ></div>

    <div
      class="AppDropdownOption__word"
      v-else-if="withWord && option.color && !withColorLabel"
      :style="{ background: option.color }"
    >
      {{ nameToLetter(option.name || option.email) }}
    </div>

    <div class="AppDropdownOption__name">
      {{ option.name || option.email }}
    </div>
  </button>
</template>

<script>
import VIcon from '@/components/ui/VIcon';

export default {
  name: 'VDropdownOption',
  components: {
    VIcon,
  },

  props: {
    selected: {
      type: Object,
      default: null,
    },
    withImg: {
      type: Boolean,
      default: null,
    },
    option: {
      type: Object,
      default: null,
    },
    target: {
      type: Number,
      default: null,
    },
    index: {
      type: Number,
      default: null,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    isProject: {
      type: Boolean,
      default: false,
    },
    withColorLabel: {
      type: Boolean,
      default: false,
    },
    withWord: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    selectedId() {
      return this.selected
        ? this.selected.id
        : false;
    },
  },
  watch: {
    target() {
      this.focus();
    },
  },

  mounted() {
    if (this.autofocus) {
      this.focus();
    }
  },

  methods: {
    focus() {
      if (this.target === this.index) {
        this.$refs.button.focus();
      }
    },
    nameToLetter(name) {
      return name.slice(0, 1);
    },
  },
}
</script>

<style lang="scss">
.dark-theme {
  .AppDropdownOption {
    color: $darkThemeText;
    $root: &;

    &_disabled {
      color: $darkThemeText;
    }

    &__img {
      border: 1px solid $darkThemeBgThree;
    }

    &_hr {
      border-bottom: 1px solid $darkThemeBorderDark;
    }

    &:hover,
    &:focus {
      background: $darkThemeBgOne;
      color: $darkThemeTextLight;

      .AppDropdownOption__icon {
        svg {
          path {
            &:nth-child(1) {
              fill: $darkThemeLightBlue
            }

            &:nth-child(2) {
              fill: $darkThemeBlue
            }
          }
        }
      }

      #{$root} {
        &__img {
          border-color: $darkThemeBgOne;
        }
      }
    }

    &_active {
      background: $darkThemeBgThree !important;
      color: $darkThemeTextLight;

      #{$root} {
        &__img {
          border-color: $blue !important;
        }
      }
    }

    &__icon {
      svg {
        path {
          &:nth-child(1) {
            fill: $darkThemeIconLightGrey
          }

          &:nth-child(2) {
            fill: $darkThemeIconGrey
          }
        }
      }
    }

    &_red {
      color: $red;

      &:hover,
      &:focus {
        background: $red;
        color: $darkThemeTextLight;
      }
    }
  }
}

.AppDropdownOption {
  height: 32px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 14px;
  font-size: rem(13px);
  color: #5d5e5e;
  display: flex;
  align-items: center;
  min-height: 36px;
  cursor: pointer;
  user-select: none;
  appearance: none;
  outline: none;
  border: none;
  width: 100%;
  $root: &;

  &_disabled {
    color: $grey;
    pointer-events: none;
    cursor: default;
  }

  &_hr {
    border-bottom: 1px solid $borderLight;
  }

  &__icon {
    flex: none;
    margin-right: 8px;

    svg {
      display: block;
      width: 18px;
      height: 18px;

      path {
        &:nth-child(1) {
          fill: $lightGrey
        }

        &:nth-child(2) {
          fill: $grey
        }
      }
    }
  }

  &__color {
    border-radius: 50%;
    flex: none;
    width: 16px;
    height: 16px;
    margin-right: 11px;
    margin-left: 1px;
  }

  &__img {
    display: block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 8px;
    flex: none;
    border: 1px solid $grey;

    img {
      display: block;
      width: 100%;
    }
  }

  &:hover,
  &:focus {
    background: $lightThemeBackground2;

    #{$root} {
      &__img {
        // border-color: #f9faff;
      }

      &__icon {
        svg {
          path {
            &:nth-child(1) {
              fill: $lightBlue
            }

            &:nth-child(2) {
              fill: $blue
            }
          }
        }
      }
    }
  }

  &_active {
    background: $blue !important;
    color: #fff;

    #{$root} {
      &__img {
        border-color: $blue !important;
      }

      &__icon {
        svg {
          path {
            fill: #fff;
          }
        }
      }
    }
  }

  &_red {
    color: $red;

    &:hover,
    &:focus {
      background: $red;
      color: #fff;
    }
  }
}
</style>
